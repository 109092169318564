/* Global Styles */
body {
  margin: 0;
  font-family: 'Lexend', 'Noto Sans', sans-serif;
  background-color: #ffffff;
  color: #141414;
}

/* Landing Page Styles */
.landing-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 1px solid #f4f4f4;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo svg {
  width: 16px;
  height: 16px;
}

.nav {
  display: flex;
  gap: 36px;
}

.nav a {
  text-decoration: none;
  color: #141414;
  font-size: 14px;
  font-weight: 500;
}

.actions {
  display: flex;
  gap: 8px;
}

.btn-primary,
.btn-secondary {
  padding: 10px 16px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.btn-primary {
  background-color: #39e079;
  color: #141414;
  border: none;
}

.btn-secondary {
  background-color: #f4f4f4;
  color: #141414;
  border: none;
}

.hero {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 480px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), url("https://cdn.usegalileo.ai/sdxl10/17935d6c-bc12-4883-bdf1-a5a898dfa88b.png");
  background-size: cover;
  background-position: center;
  padding: 40px;
  border-radius: 12px;
  margin: 20px 40px;
}

.hero-content {
  text-align: left;
  color: white;
}

.hero-content h1 {
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 8px;
}

.hero-content p {
  font-size: 14px;
  margin-bottom: 24px;
}

.search-bar {
  display: flex;
  width: 100%;
  max-width: 480px;
}

.search-bar input {
  flex: 1;
  padding: 10px 15px;
  border: 1px solid #e0e0e0;
  border-right: none;
  border-radius: 12px 0 0 12px;
  font-size: 14px;
}

.search-bar button {
  padding: 10px 20px;
  background-color: #39e079;
  border: none;
  border-radius: 0 12px 12px 0;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.content {
  padding: 40px;
}

.content h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(158px, 1fr));
  gap: 12px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.card-image {
  width: 100%;
  aspect-ratio: 16/9;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
}

.card-title {
  font-size: 16px;
  font-weight: 500;
}

.card-description {
  font-size: 14px;
  color: #666;
}

.coming-soon {
  padding: 40px;
  text-align: center;
  background-color: #f4f4f4;
  margin-top: 40px;
}

.coming-soon h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 16px;
}

.coming-soon p {
  font-size: 16px;
  color: #666;
}

/* Role Selection Styles */
.role-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f4f4f4;
}

.role-selection h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.role-buttons {
  display: flex;
  gap: 16px;
}

.role-buttons button {
  padding: 10px 20px;
  background-color: #39e079;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}