/* Global Styles */
body {
    margin: 0;
    font-family: 'Lexend', 'Noto Sans', sans-serif;
    background-color: #ffffff;
    color: #141414;
  }
  
  /* Dashboard Layout */
  .dashboard {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;
    border-bottom: 1px solid #f4f4f4;
  }
  
  .logo {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .logo svg {
    width: 16px;
    height: 16px;
  }
  
  .logo h2 {
    font-size: 18px;
    font-weight: bold;
  }
  
  .nav {
    display: flex;
    gap: 36px;
  }
  
  .nav a {
    text-decoration: none;
    color: #141414;
    font-size: 14px;
    font-weight: 500;
  }
  
  .actions {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    border: none;
    border-radius: 12px;
    padding: 8px;
    cursor: pointer;
  }
  
  .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
  }
  
  /* Main Content */
  .main-content {
    padding: 20px 40px;
  }
  
  .welcome-message {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .study-plan {
    margin-bottom: 20px;
  }
  
  .progress-bar {
    width: 100%;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .progress {
    height: 100%;
    background-color: #39e079;
  }
  
  .progress-text {
    font-size: 14px;
    color: #666;
    margin-top: 8px;
  }
  
  .motivation-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .motivation-title {
    font-size: 16px;
    font-weight: bold;
  }
  
  .motivation-text {
    font-size: 14px;
    color: #666;
  }
  
  .continue-button {
    background-color: #39e079;
    color: #141414;
    border: none;
    border-radius: 12px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .section-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .activity-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 20px;
  }
  
  .activity-item {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .activity-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
  }
  
  .activity-details p {
    margin: 0;
  }
  
  .activity-time {
    font-size: 14px;
    color: #666;
  }
  
  .recommended-grid,
  .quick-access-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 16px;
    margin-bottom: 20px;
  }
  
  .recommended-item,
  .quick-access-item {
    background-size: cover;
    background-position: center;
    border-radius: 12px;
    aspect-ratio: 16/9;
  }
  
  .quick-access-item p {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
  }
/* Dashboard.css */
.btn-logout {
    display: flex;
    align-items: center;
    background: linear-gradient(135deg, #ff6b6b, #ff4757);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .btn-logout:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .btn-logout img {
    width: 20px;
    margin-right: 8px;
  }